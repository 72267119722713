<!-- 설비유형코드 상세 (EquipTypeDetail) -->
<template>
    <vvo v-slot="v">
        <div class="detail-container">
            <!-- 1.선택 없음 표시 영역 -->
            <div v-if="!detailInfo" class="detail-placeholder">
                <span class="detail-notification-icon">
                    <i class="fas fa-external-link-alt"></i>
                </span>
                <span class="detail-notification-text">설비유형코드를 선택하세요.</span>
            </div>

            <!-- 2.상세 정보 제목 영역 -->
            <div style="height: calc(100% - 70px)" v-if="detailInfo">
                <div class="detail-header">
                    <div class="col detail-title">
                        <span class="title">{{ viewTitle }}</span>
                        <span class="badge title1"> {{ detailInfo.equipTypeName }} </span>
                        <span class="badge title2"> {{ detailInfo.equipType }} </span>
                        <span class="badge others"> {{ category }} </span>
                    </div>
                    <div v-if="!isNewMode" class="detail-control">
                        <b-form-checkbox switch v-model="isEditMode" size="lg" />
                    </div>
                </div>

                <div v-if="detailInfo" class="detail-content">
                    <BaseInfoPanel :detailInfo="detailInfo" :mode="mode" />

                    <!-- 서브 패널 영역 -->
                    <div
                        style="
              padding-left: 16px;
              padding-right: 16px;
              width: 100%;
              height: calc(100% - 238px);
              overflow: hidden;
            "
                    >
                        <b-card no-body>
                            <b-tabs card>
                                <b-tab title="계측 속성">
                                    <IoPropListPanel :detailInfo="detailInfo" :mode="mode" />
                                </b-tab>
                                <b-tab title="계산 속성">
                                    <CalcPropListPanel :detailInfo="detailInfo" :mode="mode" />
                                </b-tab>
                                <b-tab title="사용자 정의 속성">
                                    <UserPropListPanel :detailInfo="detailInfo" :mode="mode" />
                                </b-tab>
                                <b-tab title="제어 모드">
                                    <CtrlModeListPanel :detailInfo="detailInfo" :mode="mode" />
                                </b-tab>
                                <!-- <b-tab title="설비 목록">
                <facility-list-panel />
              </b-tab> -->
                            </b-tabs>
                        </b-card>
                    </div>
                </div>
            </div>

            <!-- 3.하부 버튼 영역 -->
            <div class="detail-bottom" v-if="detailInfo">
                <div class="detail-bottom-info">
                    <span>{{ `관리자: ${detailInfo.regWorker ? detailInfo.regWorker : "-"}` }}</span>
                    <span>{{ `생성일자: ${detailInfo.regDate ?? "-"}` }}</span>
                    <span>{{ `수정일자: ${detailInfo.modDate ?? "-"}` }}</span>
                </div>

                <!-- @click="handleSave(detailInfo, isEditMode)" -->
                <div class="detail-bottom-buttons mt-2">
                    <button
                        type="button"
                        class="btn btn-primary detail-btn"
                        v-if="isEditMode"
                        style="flex: 1"
                        @click="onValidate(v, saveData)"
                    >
                        저장
                    </button>
                    <button
                        type="button"
                        class="btn btn-secondary detail-btn"
                        v-if="detailInfo"
                        style="flex: 1"
                        @click="handleClose($event)"
                    >
                        닫기
                    </button>
                </div>
            </div>
        </div>
    </vvo>
</template>

<script>
import BaseInfoPanel from "./panels/BaseInfoPanel.vue";
import IoPropListPanel from "./panels/IoPropListPanel.vue";
import CalcPropListPanel from "./panels/CalcPropListPanel.vue";
import UserPropListPanel from "./panels/UserPropListPanel.vue";
import CtrlModeListPanel from "./panels/CtrlModeListPanel.vue";
//import FacilityListPanel from "../equip-type/panels/FacilityListPanel";

import backEndApi from "@src/api/backEndApi";
import moment from "moment";
import * as popupMessages from "@src/consts/popupMessageConsts";

export default {
    components: {
        BaseInfoPanel,
        IoPropListPanel,
        CalcPropListPanel,
        UserPropListPanel,
        CtrlModeListPanel,
        //FacilityListPanel,
    },
    props: ["title", "equipType"],
    async created() {},
    mounted() {},
    data() {
        return {
            detailInfo: null,
            mode: "view", // 편집모드 : edit/view
            popupMessages,
        };
    },
    computed: {
        isEditMode: {
            get() {
                return this.mode === "edit" || this.mode === "new";
            },
            set(value) {
                this.mode = value ? "edit" : "view";
            },
        },
        isNewMode() {
            return this.mode === "new";
        },
        isViewMode() {
            return this.mode === "view";
        },
        viewTitle() {
            return this.title;
        },
        category() {
            let find = this.$store.state.commonCodes.equipCategory.find(
                (item) => item.value == this.detailInfo.equipCategory
            );
            return find ? find.text : "-";
        },
    },
    watch: {
        equipType() {
            if (!this.equipType) {
                // clear detailInfo
                this.clearData();
            } else {
                // load detailInfo
                this.loadData();
            }
        },
    },
    methods: {
        // list-Detail Common Functions
        async notifyCancelEdit() {
            // return true : Edit Cancel & Proceed Next Step
            //        false: Stop.
            if (this.isEditMode) {
                const result = await this.alertConfirmWarning("편집중인 내용을 무시하고 종료할까요?");
                if (result.value) return true;
                else return false;
            }

            return true;
        },

        // list-Detail Data Functions
        newData() {
            this.mode = "new";

            this.detailInfo = {
                equipType: null,
                equipCategory: null,
                equipTypeName: null,
                driverType: null,
                ptMapType: null,
                enabled: "Y",
                description: null,
                icon: null,
                ioProps: [],
                calcProps: [],
                userProps: [],
                ctrlModes: [],
            };

            return this.detailInfo;
        },

        async loadData() {
            if (this.equipType) {
                try {
                    let result = await backEndApi.equipTypes.getEquipType(this.equipType);

                    if (result.status == 200) {
                        result.data.regDate = result.data.regDate
                            ? moment(result.data.regDate).format("YYYY-MM-DD HH:mm:ss")
                            : null;
                        result.data.modDate = result.data.modDate
                            ? moment(result.data.modDate).format("YYYY-MM-DD HH:mm:ss")
                            : null;
                        this.detailInfo = { ...result.data };
                        this.mode = "view";
                    } else {
                        this.alertWarning(
                            `${result.data.message}`,
                            "",
                            `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                        );
                    }
                } catch (e) {
                    console.error(e);
                }
            } else {
                this.newData();
            }
        },

        clearData() {
            this.detailInfo = null;
            this.mode = "view";

            this.$forceUpdate();
        },

        async saveData() {
            try {
                let result = await backEndApi.equipTypes.saveEquipType(this.detailInfo);
                if (result.status == 200) {
                    await this.alertNoti(popupMessages.COMMON_SAVE_POPUP_MESSAGE);

                    if (this.isNewMode) this.$emit("data-updated", "insert", this.detailInfo);
                    else this.$emit("data-updated", "update", this.detailInfo);

                    this.mode = "view";
                } else {
                    this.alertWarning(
                        `${result.data.message}`,
                        "",
                        `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                    );
                }
            } catch (e) {
                console.error(e);
            }
        },

        async deleteData() {
            const result = await this.alertConfirmWarning(popupMessages.COMMON_DELETE_CONFIRM_POPUP_MESSAGE);
            if (!result.value) return;

            const deletedEquipType = this.detailInfo.equipType;

            try {
                const equipType = [deletedEquipType];
                const result = await backEndApi.equipType.deleteEquipType(equipType);

                if (result.status != 200) {
                    this.alertWarning(
                        `${result.data.message}`,
                        "",
                        `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                    );
                    return;
                }

                this.clearData();
                this.$emit("data-updated", "delete", deletedEquipType);

                this.alertNoti(popupMessages.COMMON_DELETE_POPUP_MESSAGE);
            } catch (e) {
                console.error(e);
            }
        },

        getData() {
            return this.detailInfo;
        },

        // Private Event Handling Functions
        async handleSave(info) {
            if (this.isNewMode) info.action = "create";
            else info.action = "update";
            await this.saveData(info);
        },

        async handleClose(event) {
            const result = await this.notifyCancelEdit();
            if (!result) {
                event && event.preventDefault();
                return false;
            }
            // this.toggleEditMode();
            this.clearData();
            this.$emit("closed", this.equipType);

            return true;
        },

        // Equip Type Methods
    },
};
</script>

<style scoped>
.detail-container .card .tabs .tab-content .card-body {
    padding-top: 0px;
}

.detail-notification-icon {
    padding: 1rem;
    color: #444;
    font-size: 48px;
}

.detail-notification-text {
    font-size: 15px;
    font-weight: bold;
}

.detail-container {
    height: 100%;
    width: 100%;
    overflow: hidden;
    padding: 1rem 1rem 1rem 1rem;

    display: flex;
    flex-direction: column;

    border-radius: 10px;
    font-size: 12px;
}

.detail-placeholder {
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.detail-header {
    margin-bottom: 0px !important;
    padding: 8px 16px 8px 16px;
    overflow: hidden;

    display: flex;
    justify-content: space-between;
    align-items: center;

    border-radius: 6px 6px 0px 0px;
    border: solid #ececec 1px;
    border-bottom: 1px solid #ccc;
    background-color: #f1f1f1;
    font-weight: bold;
}

.detail-header .detail-title {
    padding: 0 !important;

    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.detail-content {
    height: Calc(100% - 50px);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
    padding: 20px;
    overflow: auto;
    box-sizing: border-box;

    background-color: white;
    border: solid #ececec 1px;
    border-radius: 0px 0px 6px 6px;
}

.detail-itemview {
    /* height: 200px; */
    width: 100%;
    padding: 1rem 1rem 1rem 1rem;
    justify-content: center;
    align-items: center;
    /* overflow-y: scroll; */
    border: solid #ececec 1px;
    /* border: solid red 1px; */
    border-radius: 0px 0px 6px 6px;
    /* padding: 1rem 1rem; */
}

.detail-control {
    margin-bottom: 8px !important;
    display: flex;
    justify-content: flex-end;
}

.detail-menu {
    font-size: 0.8rem;
    font-weight: bold;
}

.detail-bottom {
    margin-top: auto;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 40 px;
}

.detail-bottom-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.detail-btn {
    /* height: 50px; */
    flex: 1;
    margin: 0px 4px 0px 4px;
}

.detail-bottom-info {
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 4px 8px 4px 8px;
}

.title-icon {
    font-size: 1rem !important;
}

.input-form {
    width: 100%;
    /* 부모 요소에 꽉 맞춤 */
    box-sizing: border-box;
    /* 입력 폼 요소의 패딩과 보더를 포함하여 크기를 계산 */
}

.input-form input {
    width: 100%;
    /* 입력 요소가 부모 요소에 꽉 맞춤 */
    padding: 10px;
    /* 선택적으로 패딩 설정 */
    margin-bottom: 10px;
    /* 선택적으로 아래쪽 여백 추가 */
}

.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
    display: none;
}

.title {
    /* font-size: 14px; */
    font-weight: 600;
    margin-right: 20px;
}

.badge {
    font-size: 12px;
    font-weight: 500;
    margin-right: 4px;
    display: inline-block;
    min-width: 20px;
    padding: 0px 10px 0px 10px;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    /* background-color: #777; */
    line-height: 22px;
    border-radius: 8px;
}

.badge.title1 {
    background-color: #ff6d00;
    color: #fff;
}

.badge.title2 {
    background-color: #546e7a;
    color: #fff;
}

.badge.others {
    background-color: #cfd8dc;
    color: #000;
}

.status-icon {
    font-size: 16px;
    color: #546e7a;
    background-color: #eceff1;
    border: solid #b0bec5 1px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.2rem;
    transition: background-color 0.5s;
    width: 2rem;
    height: 2rem;
}

.status-icon:hover {
    /* color: #546E7A; */
    background-color: #b0bec5;
}

.status-icon.active {
    color: #eceff1;
    background-color: #546e7a;
}
</style>
