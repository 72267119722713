<template>
    <vvo v-slot="v">
        <div class="container">
            <div class="row flex-grow-1">
                <div class="col">
                    <!-- TableList -->
                    <TableListComponent
                        ref="TableListComponent"
                        :title="''"
                        :columns="columns"
                        :rows="detailInfo.userProps"
                        :keyField="'propCode'"
                        :transCodes="transCodes"
                        :controls="controls"
                        :mode="mode"
                        :useMaxHeight="true"
                        :toggleFields="['enabled']"
                        @button-click="handleListButtonClick"
                        :useFilter="false"
                        @row-click="handleListItemClick"
                        @toggle-changed="handleItemToggle"
                    />

                    <!-- UserPropDetail Popup -->
                    <b-modal ref="UserPropDetailModal" size="lg" centered @hidden="closeUserPropModal">
                        <template #modal-header>
                            <h5>
                                {{
                                    tempUserPropIndex !== null || undefined
                                        ? "사용자 정의 속성 편집"
                                        : "사용자 정의 속성 추가"
                                }}
                            </h5>
                        </template>
                        <!-- @click="handleAddListItem" -->
                        <template #modal-footer>
                            <div style="display: flex; flex: 1">
                                <button
                                    v-show="isEditMode"
                                    type="button"
                                    class="btn btn-primary"
                                    style="flex: 1"
                                    :style="{ marginRight: isEditMode ? '0.5rem' : '' }"
                                    @click="onValidate(v, handleAddListItem)"
                                >
                                    저장
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-secondary"
                                    style="flex: 1"
                                    :style="{ marginLeft: isEditMode ? '0.5rem' : '' }"
                                    @click="closeUserPropModal"
                                >
                                    닫기
                                </button>
                            </div>
                        </template>

                        <UserPropDetailPanel ref="UserPropDetailPanel" :mode="mode" />
                    </b-modal>
                </div>
            </div>
        </div>
    </vvo>
</template>

<script>
import TableListComponent from "@views/component/v2.1/ListDetailView/TableList.vue";
import UserPropDetailPanel from "./UserPropDetailPanel.vue";
import * as popupMessages from "@src/consts/popupMessageConsts";

export default {
    components: {
        TableListComponent,
        UserPropDetailPanel,
    },
    props: ["detailInfo", "mode"],
    created() {},
    mounted() {},
    data() {
        return {
            controls: [
                { event: "delete", label: "-", class: "btn-danger" },
                { event: "regist", label: "+", class: "btn-warning" },
            ],
            transCodes: [
                {
                    field: "enabled",
                    codes: this.$store.state.commonCodes.enabled,
                },
                {
                    field: "unitType",
                    codes: this.$store.state.unitTypes,
                },
                {
                    field: "unit",
                    codes: this.$store.state.units,
                },
            ],
            columns: [
                {
                    label: this.$t("속성 코드"),
                    field: "propCode",
                    type: "text",
                    tdClass: "text-center font-weight-bold text-dark",
                },
                {
                    label: this.$t("속성 코드명"),
                    field: "propName",
                    type: "text",
                    tdClass: "text-center font-weight-bold text-dark",
                },
                {
                    label: this.$t("단위 유형"),
                    field: "unitType",
                    type: "text",
                },
                {
                    label: this.$t("단위"),
                    field: "unit",
                    type: "text",
                },
                {
                    label: this.$t("사용 여부"),
                    field: "enabled",
                    type: "text",
                    width: "80px",
                },
            ],
            tempUserPropIndex: null,
            popupMessages,
        };
    },
    computed: {
        isEditMode() {
            return this.mode !== "view";
        },
    },
    watch: {
        detailInfo() {},
    },
    methods: {
        async handleListButtonClick(event, options) {
            const that = this;

            switch (event) {
                case "regist":
                    await that.registNew();
                    break;

                case "delete":
                    await that.deleteChecked();
                    break;

                default:
                    console.warn("Unhandled Component Event. event:", event, "options:", options);
                    break;
            }
        },
        async handleListItemClick(row, index, toggle, check) {
            const that = this;
            if (toggle || check) return;
            that.tempUserPropIndex = index;
            let findUserProp = this.detailInfo.userProps.find((item) => item.propCode == row.propCode);
            await that.$refs.UserPropDetailModal.show();
            await that.$refs.UserPropDetailPanel.setData(findUserProp);
        },
        closeUserPropModal() {
            const that = this;
            if (this.tempUserPropIndex !== null || undefined) this.tempUserPropIndex = null;
            that.$refs.UserPropDetailModal.hide();
        },
        async deleteChecked() {
            const that = this;

            try {
                const checked = this.$refs["TableListComponent"].getCheckedRows();

                if (!that.isEmpty(checked)) {
                    const confirm = await this.alertConfirmWarning(popupMessages.COMMON_DELETE_CONFIRM_POPUP_MESSAGE);
                    if (!confirm.value) return;
                    checked.forEach(async () => {
                        // await backEndApi.propCodes.deleteEquipType(item.propCode);
                        // detailInfo에서 checked 항목을 제외.
                        that.detailInfo.userProps = that.detailInfo.userProps.filter((prop) => {
                            return !checked.find((checkedProp) => checkedProp.propCode === prop.propCode);
                        });
                    });
                    // await this.getDataList();
                } else {
                    this.alertNoti(popupMessages.COMMON_DELETE_NOTI_POPUP_MESSAGE);
                }
            } catch (e) {
                this.alertNoti(popupMessages.COMMON_REMOVE_ERROR_POPUP_MESSAGE);
                console.error(e);
            }
        },
        async registNew() {
            const that = this;
            that.tempUserProp = null;

            that.$refs.UserPropDetailModal.show();
        },
        handleAddListItem() {
            // this.detailInfo.inProps.push(this.inPropBuffer);
            const userProp = this.$refs.UserPropDetailPanel.getData();

            if (userProp) {
                if (this.tempUserPropIndex !== null || undefined) {
                    this.$set(this.detailInfo.userProps, this.tempUserPropIndex, userProp);
                    this.tempUserPropIndex = null;
                } else {
                    let check = this.detailInfo.userProps.find(
                        (prop) => prop.propCode === userProp.propCode || prop.propName === userProp.propName
                    );
                    if (check) {
                        // TODO: Alert Message
                        console.error("TODO: Alert Message");
                        console.error(
                            `In Property is already exists in userProps. propCode:${userProp.propCode}, propName:${userProp.propName}`
                        );
                    } else {
                        this.$set(this.detailInfo.userProps, this.detailInfo.userProps.length, userProp);
                    }
                }
            }

            this.$refs.UserPropDetailModal.hide();
        },
        ddhandleAddListItem() {
            const calcProp = this.$refs.CalcPropDetailPanel.getData();

            if (calcProp) {
                if (this.tempCalcPropIndex !== null || undefined) {
                    this.$set(this.detailInfo.calcProps, this.tempCalcPropIndex, calcProp);
                    this.tempCalcPropIndex = null;
                } else {
                    let check = this.detailInfo.calcProps.find(
                        (prop) => prop.propCode === calcProp.propCode || prop.propName === calcProp.propName
                    );
                    if (check) {
                        // TODO: Alert Message
                        console.error("TODO: Alert Message");
                        console.error(
                            `In Property is already exists in calcProps. propCode:${calcProp.propCode}, propName:${calcProp.propName}`
                        );
                    } else {
                        this.$set(this.detailInfo.calcProps, this.detailInfo.calcProps.length, calcProp);
                    }
                }
            }

            this.$refs.CalcPropDetailModal.hide();
        },
        handleItemToggle(key, field, status) {
            const that = this;
            const found = that.detailInfo.userProps.find((item) => item.propCode === key);
            found.enabled = status;
        },
    },
};
</script>

<style scoped>
.col {
    position: relative;
    width: 100%;
    padding-right: 4px;
    padding-left: 4px;
}
</style>
