<template>
    <div class="container">
        <div class="row flex-grow-0">
            <div class="col col-md-2">
                <div class="col" style="height: 100%; width: 100%">
                    <span class="control-icon" @click="toggleIconSelect" :disabled="!isEditMode">
                        <i :class="detailInfo.icon" style="font-size: 1rem; margin-right: 10px"></i>
                    </span>
                    <icon-select
                        v-if="isIconSelectOpen"
                        :current="detailInfo.icon"
                        :hideText="true"
                        @on-change="onChangeIcon"
                    />
                </div>
            </div>
            <div class="col col-md-4">
                <label class="col" for="equipCode">
                    <span>{{ $t("설비유형코드") }}</span>
                    <i class="material-icons ml-1" :style="{ fontSize: '0.8rem' }">check_circle</i>
                </label>
                <div class="col">
                    <!-- <input v-model="detailInfo.equipType" type="text" class="form-control" id="equipCode"
                        :disabled="!isNewMode"> -->
                    <valid-input
                        :vid="'설비 유형 코드'"
                        :classList="'form-control'"
                        :inputType="'text'"
                        :inputValue.sync="detailInfo.equipType"
                        :placeholder="$t('설비 유형 코드')"
                        :rules="rules.COMMON_CODE_RULE"
                        :errorMessage="validMessage.COMMON_CODE_VALID_MESSAGE"
                        :disabled="!isNewMode"
                    ></valid-input>
                </div>
            </div>
            <div class="col col-md-4">
                <label class="col-md-12 d-flex align-items-center" for="equipTypeName">
                    <span>{{ $t("설비유형명") }}</span>
                    <i class="material-icons ml-1" :style="{ fontSize: '0.8rem' }">check_circle</i>
                </label>
                <div class="col-md-12">
                    <!-- <input v-model="detailInfo.equipTypeName" type="text" class="form-control" id="equipTypeName"
                        :disabled="!isEditMode"> -->
                    <valid-input
                        :vid="'설비 유형명'"
                        :classList="'form-control'"
                        :inputType="'text'"
                        :inputValue.sync="detailInfo.equipTypeName"
                        :placeholder="$t('설비 유형명')"
                        :rules="rules.COMMON_NAME_RULE"
                        :errorMessage="validMessage.COMMON_NAME_VALID_MESSAGE"
                        :disabled="!isEditMode"
                    ></valid-input>
                </div>
            </div>
            <div class="col col-md-2">
                <label class="col" for="equipEnabled">사용여부</label>
                <div class="col">
                    <select v-model="detailInfo.enabled" class="form-control" id="equipEnabled" :disabled="!isEditMode">
                        <option value="">사용여부선택</option>
                        <option v-for="code in $store.state.commonCodes.enabled" :key="code.value" :value="code.value">
                            {{ code.text }}
                        </option>
                    </select>
                </div>
            </div>
        </div>

        <div class="row flex-grow-0">
            <div class="col col-md-4">
                <label class="col-md-12" for="connDriver">연결 드라이버</label>
                <div class="col-md-12">
                    <input
                        v-model="detailInfo.driverType"
                        type="text"
                        class="form-control"
                        id="connDriver"
                        :disabled="!isEditMode || detailInfo.driverType !== ''"
                    />

                    <!-- Seledct Driver Modal -->
                    <b-modal centered ref="SelectDriverModal" @hidden="cancelSelectDriverModal()">
                        <template #modal-header>
                            <span style="font-size: 14px; font-weight: bold">연결할 드라이버 선택</span>
                        </template>

                        <template #modal-footer>
                            <button type="button" class="btn btn-sm btn-primary" @click="handleSelectDriverModal()">
                                선택
                            </button>
                            <button type="button" class="btn btn-sm btn-secondary" @click="cancelSelectDriverModal()">
                                닫기
                            </button>
                        </template>

                        <SelectDriverPanel ref="SelectDriverPanel" :mode="mode" />
                    </b-modal>
                </div>
            </div>
            <div class="col col-md-4">
                <label class="col" for="equipCategory">
                    <span>{{ $t("설비 분류") }}</span>
                    <i class="material-icons ml-1" :style="{ fontSize: '0.8rem' }">check_circle</i>
                </label>
                <div class="col">
                    <!-- <select v-model="detailInfo.equipCategory" class="form-control" id="equipCategofy"
                        :disabled="!isEditMode">
                        <option value="">설비 분류 선택</option>
                        <option v-for="code in $store.state.commonCodes.equipCategory" :key="code.value"
                            :value="code.value">
                            {{ code.text }}
                        </option>
                    </select> -->
                    <valid-select
                        :vid="`설비 분류`"
                        :vidName="'설비 분류'"
                        :inputValue.sync="detailInfo.equipCategory"
                        :selectOption="commonCodes.equipCategory"
                        :rules="rules.COMMON_SELECT_REQUIRED"
                        :errorMessage="validMessage.EQUIP_TYPE_CATEGORY_VALID_MESSAGE"
                        :useCommonCode="true"
                    >
                    </valid-select>
                </div>
            </div>
            <div class="col col-md-4">
                <label class="col" for="ptMapType">
                    <span>{{ $t("관제점 매핑 유형") }}</span>
                    <i class="material-icons ml-1" :style="{ fontSize: '0.8rem' }">check_circle</i>
                </label>
                <div class="col">
                    <!-- <select v-model="detailInfo.ptMapType" class="form-control" id="ptMapType" :disabled="!isEditMode">
                        <option value="">선택</option>
                        <option v-for="(type) in $store.state.commonCodes.ptMapType" :key="type.value"
                            :value="type.value">
                            {{ type.text }}
                        </option>
                    </select> -->
                    <valid-select
                        :vid="`관제점 매핑 유형`"
                        :vidName="'관제점 매핑 유형'"
                        :inputValue.sync="detailInfo.ptMapType"
                        :selectOption="commonCodes.ptMapType"
                        :rules="rules.COMMON_SELECT_REQUIRED"
                        :errorMessage="validMessage.EQUIP_TYPE_CATEGORY_VALID_MESSAGE"
                        :useCommonCode="true"
                    >
                    </valid-select>
                </div>
            </div>
        </div>

        <div class="row flex-grow-0">
            <div class="col-md-1" style="text-align: center">
                <label for="equipDesc">설명</label>
            </div>
            <div class="col-md-11">
                <!-- <textarea v-model="detailInfo.description" type="text" class="form-control" id="equipDesc"
                    :disabled="!isEditMode" style="resize: none; overflow-y: auto;" rows="3"></textarea> -->
                <valid-textarea
                    :inputType="'text'"
                    :inputValue.sync="detailInfo.description"
                    :NonStatus="true"
                    :rows="3"
                    :classList="'desc-ctn form-control'"
                    :rules="rules.COMMON_DESC_RULE"
                    :disabled="!isEditMode"
                >
                </valid-textarea>
            </div>
        </div>
    </div>
</template>

<script>
import * as rules from "@src/consts/ruleConsts.js";
import * as validMessage from "@src/consts/validMessageConsts.js";
import { mapState } from "vuex";
import SelectDriverPanel from "./SelectDriverPanel.vue";
import backEndApi from "@src/api/backEndApi";

export default {
    components: {
        SelectDriverPanel,
    },
    props: ["detailInfo", "mode"],
    data() {
        return {
            selectedDriver: "",
            selectedDriverProps: [],
            isIconSelectOpen: false,
            rules,
            validMessage,
        };
    },
    async created() {},
    mounted() {
        if (this.isNewMode) this.$refs.SelectDriverModal.show();
    },
    computed: {
        ...mapState({
            commonCodes: (state) => state.commonCodes,
        }),
        driverList() {
            return this.$store.state.driverList;
        },
        isEditMode() {
            return this.mode !== "view";
        },
        isNewMode() {
            return this.mode === "new";
        },
    },
    watch: {
        isNewMode(newVal) {
            if (newVal) this.$refs.SelectDriverModal.show();
        },
    },
    methods: {
        cancelSelectDriverModal() {
            this.$refs.SelectDriverModal.hide();
            this.selectedDriver = null;
        },
        async handleSelectDriverModal() {
            try {
                this.selectedDriver = this.$refs.SelectDriverPanel.getDriver();

                let found = this.driverList.find(
                    (item) =>
                        item.driverType === this.selectedDriver.driverType &&
                        item.version === this.selectedDriver.version
                );
                if (found) {
                    // Initializing detailInfo with driver info.
                    this.detailInfo.equipTypeName = found.driverName;
                    this.detailInfo.driverType = found.driverType;
                    this.detailInfo.version = found.version;
                    this.detailInfo.description = found.remark;

                    // Query Driver Property for selected driver (type/version)
                    let result = await backEndApi.equipTypes.getDriverProp(
                        this.selectedDriver.driverType,
                        this.selectedDriver.version
                    );
                    if (result.status == 200) {
                        this.selectedDriverProps = result.data;

                        // Initializing detailInfo.ioProps
                        this.detailInfo.ioProps = result.data
                            // .filter( prop => ['In', 'Both'].includes(prop.direction) )
                            .map((prop) => {
                                // let spaceRemove = prop.remark.replace(/\s/g, '')
                                return {
                                    propCode: prop.propName,
                                    // propName: spaceRemove,
                                    propName: prop.propName,
                                    connDriverProp: prop.propName,
                                    dataSourceType: "Meas", // 계측
                                    ioType: prop.direction, // 입출력방향
                                    unitType: prop.unitType,
                                    unit: prop.unit,
                                    //scale: prop.scale,
                                    ptType: prop.ptType,
                                    scale: 1.0,
                                    encodeType: prop.encodeType,
                                    encodeDesc: prop.encodeDesc,
                                    enabled: "Y",
                                    description: prop.remark,
                                    dispLevel: "0",
                                };
                            });

                        let filterCtrlModes = result.data.filter((prop) => ["Out", "Both"].includes(prop.direction));

                        // Add Basic CtrlMode (Basic)
                        if (filterCtrlModes.length > 0) {
                            this.detailInfo.ctrlModes.push({
                                ctrlMode: "Basic",
                                ctrlModeName: "기본 제어 모드",
                                outProps: filterCtrlModes.map((prop) => {
                                    return {
                                        propCode: prop.propName,
                                    };
                                }),
                                enabled: "Y",
                            });
                        }
                    } else {
                        this.alertWarning(
                            `${result.data.message}`,
                            "",
                            `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                        );
                    }
                } else {
                    console.error("Driver Info is not found. selectedDrver:", this.selectedDriver);
                    this.selectedDriverProps = null;
                }

                this.$refs.SelectDriverModal.hide();
            } catch (e) {
                console.error(e);
                this.$refs.SelectDriverModal.hide();
            }
        },
        toggleIconSelect() {
            if (this.isEditMode) {
                this.isIconSelectOpen = !this.isIconSelectOpen;
            }
        },
        onChangeIcon(value) {
            this.toggleIconSelect();
            if (!this.isEmpty(value)) this.detailInfo.icon = value.icon;
        },
    },
};
</script>

<style scoped>
.container {
    margin-top: -16px;
}

.row {
    margin-bottom: 16px;
}

.col {
    position: relative;
    width: 100%;
    padding-right: 4px;
    padding-left: 4px;
}

.control-icon {
    /* font-size: 68px; */
    display: flex;
    justify-content: center;
    align-items: center;
    color: #455a64;
    background-color: #eceff1;
    transition: background-color 0.5s;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    border-left: solid 1px #cfd8dc;
    border-top: solid 1px #cfd8dc;
    border-right: solid 1px #90a4ae;
    border-bottom: solid 1px #90a4ae;
}

.control-icon:hover {
    color: #eceff1;
    background-color: #455a64;
}
</style>
