<template>
    <div class="detail-container">
        <div class="detail-header">
            <label class="col-form-label" for="selectDriver"
                >드라이버에서 항목을 선택하면 간편하게 관제 항목을 설정할 수 있습니다.</label
            >
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon3">드라이버 항목 선택</span>
                </div>
                <select
                    v-model="selectedDriver"
                    id="selectDriver"
                    class="form-control"
                    :disabled="!isEditMode"
                    @change="getDriverProp"
                >
                    <option :value="null">선택</option>
                    <option
                        v-for="(driver, index) in driverList"
                        :key="index"
                        :value="{ driverType: driver.driverType, version: driver.version }"
                    >
                        {{ driver.driverName }}
                    </option>
                </select>
                <select
                    v-if="!detailInfo"
                    v-model="selectedDriverProp"
                    id="selectDriverProp"
                    class="form-control"
                    :disabled="!isEditMode && !selectedDriver"
                >
                    <option :value="null">선택</option>
                    <option v-for="(driverProp, index) in driverPropList" :key="index" :value="driverProp.propName">
                        {{ driverProp.propName }}
                    </option>
                </select>
                <select
                    v-else
                    v-model="selectedDriverProp"
                    id="selectDriverProp"
                    class="form-control"
                    :disabled="!isEditMode && !selectedDriver"
                >
                    <option :value="null">선택</option>
                    <option v-for="(driverProp, index) in driverPropList" :key="index" :value="driverProp.propName">
                        {{ driverProp.propName }}
                    </option>
                </select>
                <div class="input-group-append">
                    <button type="button" class="btn btn-primary" @click="setDriverProp">
                        적용
                    </button>
                </div>
            </div>
        </div>
        <div class="detail-content">
            <div v-if="detailInfo" class="form-row">
                <div class="form-group col-md-5">
                    <label for="propCode">관제 항목 코드 :</label>
                    <!-- <input v-model="detailInfo.propCode" type="text" class="form-control" id="propCode"
                        :disabled="!isEditMode"> -->
                    <valid-input
                        :vid="'관제 항목 코드'"
                        :classList="'form-control'"
                        :inputType="'text'"
                        :inputValue.sync="detailInfo.propCode"
                        :placeholder="$t('관제 항목 코드')"
                        :rules="rules.COMMON_CODE_RULE"
                        :errorMessage="validMessage.COMMON_CODE_VALID_MESSAGE"
                        :disabled="!isEditMode"
                    ></valid-input>
                </div>
                <div class="form-group col-md-5">
                    <label for="propName">관제 속성명 :</label>
                    <!-- <input v-model="detailInfo.propName" type="text" class="form-control" id="propName"
                        :disabled="!isEditMode"> -->
                    <valid-input
                        :vid="'관제 속성명'"
                        :classList="'form-control'"
                        :inputType="'text'"
                        :inputValue.sync="detailInfo.propName"
                        :placeholder="$t('관제 속성명')"
                        :rules="rules.COMMON_NAME_RULE"
                        :errorMessage="validMessage.COMMON_NAME_VALID_MESSAGE"
                        :disabled="!isEditMode"
                    ></valid-input>
                </div>
                <div class="form-group col-md-2">
                    <label for="enabled">사용 여부 :</label>
                    <select v-model="detailInfo.enabled" id="enabled" class="form-control" :disabled="!isEditMode">
                        <option :value="null">선택</option>
                        <option v-for="code in $store.state.commonCodes.enabled" :key="code.value" :value="code.value">
                            {{ code.text }}
                        </option>
                    </select>
                </div>
            </div>
            <div v-if="detailInfo" class="form-row">
                <div class="form-group col-md-3">
                    <label for="dataSourceType">데이터 생성 방식 :</label>
                    <select v-model="detailInfo.dataSourceType" id="dataSourceType" class="form-control" disabled>
                        <option :value="null">선택</option>
                        <option
                            v-for="code in $store.state.commonCodes.dataSourceType"
                            :key="code.value"
                            :value="code.value"
                            id="dataSourceType"
                        >
                            {{ code.text }}
                        </option>
                    </select>
                </div>
                <div class="form-group col-md-3">
                    <label for="ptType">집계 유형 :</label>
                    <select v-model="detailInfo.ptType" id="ptType" class="form-control" :disabled="!isEditMode">
                        <option :value="null">선택</option>
                        <option
                            v-for="code in $store.state.commonCodes.ptTypeCode"
                            :key="code.value"
                            :value="code.value"
                            id="ptType"
                        >
                            {{ code.text }}
                        </option>
                    </select>
                </div>
                <div class="form-group col-md-3">
                    <label for="unitType">단위 유형 :</label>
                    <select v-model="detailInfo.unitType" id="unitType" class="form-control" :disabled="!isEditMode">
                        <option :value="null">선택</option>
                        <option
                            v-for="value in $store.state.unitTypes"
                            :key="value.value"
                            :value="value.value"
                            id="unitType"
                        >
                            {{ value.text }}
                        </option>
                    </select>
                </div>
                <div class="form-group col-md-3">
                    <label for="unit">단위 :</label>
                    <select v-model="detailInfo.unit" id="unit" class="form-control" :disabled="!isEditMode">
                        <option :value="null">선택</option>
                        <option
                            v-for="code in $store.state.unitCodes[detailInfo.unitType]"
                            :key="code.value"
                            :value="code.value"
                            id="unit"
                        >
                            {{ code.text }}
                        </option>
                    </select>
                </div>
            </div>
            <div v-if="detailInfo" class="form-row">
                <div class="form-group col-md-3">
                    <label for="ioType">입출력 유형 :</label>
                    <select v-model="detailInfo.ioType" id="ioType" class="form-control" :disabled="!isEditMode">
                        <option :value="null">선택</option>
                        <option
                            v-for="code in $store.state.commonCodes.ioType"
                            :key="code.value"
                            :value="code.value"
                            id="ioType"
                        >
                            {{ code.text }}
                        </option>
                    </select>
                </div>
                <div class="form-group col-md-3">
                    <label for="scale">스케일 적용 :</label>
                    <select v-model="detailInfo.scale" class="form-control" id="scale" :disabled="!isEditMode">
                        <option :value="null">선택</option>
                        <option value="0.001">0.001</option>
                        <option value="0.01">0.01</option>
                        <option value="0.1">0.1</option>
                        <option value="1">1</option>
                        <option value="10">10</option>
                        <option value="100">100</option>
                        <option value="1000">1000</option>
                    </select>
                </div>
                <div class="form-group col-md-3">
                    <label for="encodeType">인코딩 유형 :</label>
                    <select v-model="detailInfo.encodeType" id="unit" class="form-control" :disabled="!isEditMode">
                        <option :value="null">선택</option>
                        <option
                            v-for="code in $store.state.commonCodes.encodeType"
                            :key="code.value"
                            :value="code.value"
                        >
                            {{ code.text }}
                        </option>
                    </select>
                </div>
                <div class="form-group col-md-3">
                    <label for="representSumYn">대표값 집계 여부 :</label>
                    <select
                        v-model="detailInfo.representSumYn"
                        id="representSumYn"
                        class="form-control"
                        :disabled="!isEditMode"
                    >
                        <option :value="null">선택</option>
                        <option v-for="code in $store.state.commonCodes.enabled" :key="code.value" :value="code.value">
                            {{ code.text }}
                        </option>
                    </select>
                </div>
            </div>
            <div v-if="detailInfo" class="form-row">
                <div class="form-group col-md-4">
                    <label for="dispLevel">표시 등급 :</label>
                    <select v-model="detailInfo.dispLevel" id="dispLevel" class="form-control" :disabled="!isEditMode">
                        <option :value="null">선택</option>
                        <option
                            v-for="code in $store.state.commonCodes.dispLevel"
                            :key="code.value"
                            :value="code.value"
                        >
                            {{ code.text }}
                        </option>
                    </select>
                </div>
                <div class="form-group col-md-4">
                    <label for="dispOrder">표시 순서 :</label>
                    <!-- <input v-model="detailInfo.dispOrder" type="text" class="form-control" id="dispOrder"
                        :disabled="!isEditMode"> -->
                    <valid-input
                        :vid="'표시 순서'"
                        :classList="'form-control'"
                        :inputType="'text'"
                        :inputValue.sync="detailInfo.dispOrder"
                        :placeholder="$t('표시 순서 (1 ~ 9999)')"
                        :rules="rules.EQUIP_TYPE_DIS_ORDER"
                        :disabled="!isEditMode"
                    ></valid-input>
                </div>
                <div class="form-group col-md-4">
                    <label for="dispColor">표시 색상 :</label>
                    <!-- <input v-model="detailInfo.dispColor" type="text" class="form-control" id="dispColor"
                        :disabled="!isEditMode"> -->
                    <valid-input
                        :vid="'표시 색상'"
                        :classList="'form-control'"
                        :inputType="'text'"
                        :inputValue.sync="detailInfo.dispColor"
                        :placeholder="$t('표시 색상 (ex: #eeeeee)')"
                        :rules="rules.EQUIP_TYPE_DIS_COLOR"
                        :disabled="!isEditMode"
                    ></valid-input>
                </div>
            </div>
            <div v-if="detailInfo" class="form-row">
                <div class="form-group col-md-12">
                    <label for="encodeDesc">인코딩 규칙 :</label>
                    <!-- <textarea v-model="detailInfo.encodeDesc" type="text" class="form-control" id="encodeDesc"
                        :disabled="!isEditMode" rows="2"></textarea> -->
                    <valid-textarea
                        :inputType="'text'"
                        :inputValue.sync="detailInfo.encodeDesc"
                        :NonStatus="true"
                        :rows="2"
                        :classList="'desc-ctn form-control'"
                        :rules="rules.COMMON_DESC_RULE"
                        :disabled="!isEditMode"
                    >
                    </valid-textarea>
                </div>
            </div>
            <div v-if="detailInfo" class="form-row">
                <div class="form-group col-md-12">
                    <label for="description">설명 :</label>
                    <!-- <textarea v-model="detailInfo.description" type="text" class="form-control" id="description"
                        :disabled="!isEditMode" rows="2"></textarea> -->
                    <valid-textarea
                        :inputType="'text'"
                        :inputValue.sync="detailInfo.description"
                        :NonStatus="true"
                        :rows="2"
                        :classList="'desc-ctn form-control'"
                        :rules="rules.COMMON_DESC_RULE"
                        :disabled="!isEditMode"
                    >
                    </valid-textarea>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as rules from "@src/consts/ruleConsts.js";
import * as validMessage from "@src/consts/validMessageConsts.js";
import backEndApi from "@src/api/backEndApi";

export default {
    components: {},
    props: ["mode", "connDriver"],
    data() {
        return {
            detailInfo: null,
            driverPropList: null,

            selectedDriver: null,
            selectedDriverProp: null,
            rules,
            validMessage,
        };
    },
    computed: {
        isEditMode() {
            return this.mode !== "view";
        },
        driverList() {
            return this.$store.state.driverList;
        },
    },
    created() {
        this.detailInfo = this.initData();
    },
    async mounted() {
        this.selectedDriverProp = null;
        if (this.connDriver) {
            this.selectedDriver = this.connDriver;
        }
        if (this.detailInfo.connDriverProp) {
            this.selectedDriverProp = this.detailInfo.connDriverProp;
        }
        await this.getDriverProp();
        // this.detailInfo.dataSourceType = 'Meas';
    },
    destroyed() {},
    methods: {
        initData() {
            return {
                propCode: "",
                propName: "",
                dataSourceType: "Meas",
                connDriverProp: "",
                ioType: "",
                ptType: "",
                unitType: "",
                unit: "",
                scale: 0,
                encodeType: "",
                encodeDesc: "",
                description: "",
                dispLevel: "",
                dispOrder: null,
                dispColor: null,
                enabled: "Y",
                representSumYn: "N",
            };
        },
        setData(data) {
            if (data) {
                // this.mode = 'view';
                this.detailInfo = data;
            } else {
                // this.mode = 'edit';
                this.detailInfo = this.initData();
            }
        },
        getData() {
            return this.detailInfo;
        },
        async getDriverProp() {
            if (this.selectedDriver) {
                let driver = this.driverList.find(
                    (item) =>
                        item.driverType === this.selectedDriver.driverType &&
                        item.version === this.selectedDriver.version
                );
                if (driver) {
                    this.selectedDriverProp = null;
                    try {
                        let result = await backEndApi.equipTypes.getDriverProp(driver.driverType, driver.version);
                        if (result.status == 200) {
                            // this.driverPropList = JSON.parse(JSON.stringify(result.data));
                            // this.driverPropList = result.data.filter( prop => ['In','Both'].includes(prop.direction) );
                            this.driverPropList = result.data;
                        } else {
                            this.alertWarning(
                                `${result.data.message}`,
                                "",
                                `${typeof result.data.detail == "string" ? result.data.detail : ""}`
                            );
                        }
                    } catch (e) {
                        console.error(e);
                    }
                } else {
                    this.selectedDriver = "";
                    this.selectedDriverProp = null;
                    this.driverPropList = null;
                }
            }
        },
        setDriverProp() {
            let driverProp = this.driverPropList.find((item) => item.propName == this.selectedDriverProp);

            this.detailInfo = {
                propCode: driverProp.propName,
                propName: driverProp.propName,
                connDriverProp:
                    this.selectedDriver.driverType === this.connDriver.driverType &&
                    this.selectedDriver.version === this.connDriver.version
                        ? driverProp.propName
                        : null,
                dataSourceType: "Meas", // 드라이버로부터 항목을 선택한 것은 '계측항목'
                ioType: driverProp.direction,
                ptType: driverProp.ptType,
                unitType: driverProp.unitType,
                unit: driverProp.unit,
                scale: 1.0, // driver의 scale과는 다름. (1.0 : 수집된 값을 그대로 사용함)
                encodeType: driverProp.encodeType,
                encodeDesc: driverProp.encodeDesc,
                description: driverProp.remark,
                enabled: "Y",
                representSumYn: "N",
                dispLevel: 0,
                dispOrder: null,
                dispColor: null,
            };

            // TODO : 주 드라이버와 현재 선택된 드라이버 일치할 때에만 connDriverProp을 설정함.            )
            // this.detailInfo.connDriverProp = driverProp.propName;
        },
    },
};
</script>

<style scoped>
.detail-container {
    height: 100%;
    /* width: 100%; */
    border-radius: 8px;
    border: solid #eee 1px;
    /* background-color: white; */
    margin: 1rem;
    font-size: 12px;
}

.detail-placeholder {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.detail-header {
    background-color: #fff;
    border-radius: 8px 8px 0px 0px;
    padding: 0.8rem 1rem;
    /* margin: 1rem 0rem; */
}

.detail-content {
    height: 88%;
    width: 100%;
    border-radius: 0px 0px 8px 8px;
    border: solid #eee 1px;
    padding: 1rem 1rem;
    /* overflow-y: auto; */
}

.detail-bottom {
    display: flex;
    justify-content: space-between;
}
</style>
